.homeBottomBanner {
    background-image: url("../../../assets/images/authBottomBg.svg");
    background-position: left bottom;
    background-repeat: no-repeat;
    /* height: 245px; */
    padding: 40px 72px;
    /* position: fixed;
    bottom: 0; */
}

.homeMainDiv {
    padding: 40px 72px;
}

.childName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #141719;
    margin-bottom: 8px;
}
.childAge{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.childNameDiv {
    border: 1px solid #D8E5EE;
    border-radius: 8px;
    display: flex;
    padding: 16px;
    align-items: center;
}

.scheduleSessionBlock {
    border: 1px solid #D8E5EE;
    border-radius: 8px;
    padding: 16px;
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    color: #5A646B !important;
}

.scheduleBtn {
    background: #4299E1 !important;
    border-radius: 10px !important;
    font-family: 'Inter', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    width: 161px;
    min-height: 40px;
    margin-top: 16px;
}

.articleTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #141719;
    margin-top: 48px;
    margin-bottom: 16px;
}

.articleMainDiv {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    border: 1px solid #A6B8C4;
    border-radius: 8px;
}

.articleName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #141719;
}

.articleDesc {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5A646B;
    margin-top: 8px;
}

.othersText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #141719;
}

.sessionCountDiv {
    border: 1px solid #D8E5EE;
    border-radius: 8px;
    padding: 24px;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.scheduleDivText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141719;
    margin-bottom: 16px;
}

.scheduleDivBtn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize;
    color: #141719;
    border: 1px solid #4299E1;
    border-radius: 10px !important;
    padding: 10px 20px;
    background: #FFFFFF !important;
}

.quotesText {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5A646B;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 350px;
}

.checkpointDiv {
    border: 1px solid #D8E5EE;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* padding: 112px 56px 40px; */
    /* gap: 32px; */
}

.checkpointImg {
    margin-top: -40px;
}

.checkpointText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    color: #141719;
    margin-top: 8px;
}

.progressBarDiv {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px 60px;
}

.checkpointServiceName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-top: 8px;
}

.checkpointServiceDiv {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    width: 150px;
    cursor: pointer;

}

.sessionLeftText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141719;
    margin-top: 20px;
}

.validTillText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5A646B;
    margin-bottom: 16px;
}

.sessionBtnDiv {
    display: flex;
    justify-content: space-between;
}
.topUpDiv{
    background:  #FFDAD6;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    color: #410002;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
}
.topUpBtn{
    border-radius: 10px !important;
    background: #BA1A1A !important;
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px;
    color:  #FFF !important;
    font-family: "Inter",sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important; /* 142.857% */
    text-transform: capitalize;
}
.upcomingDiv{
    background:  #E9F6FF;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    color: #1A365D;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
}
@media screen and (max-width:767px) {
    .homeBottomBanner {
        padding: 16px;
        background-image: none;
        margin-bottom: 100px;
    }

    .sessionCountDiv {
        margin-top: 0;
        padding: 16px;
    }

    .sessionBtnDiv {
        flex-direction: column;
    }

    .scheduleDivBtn {
        width: 270px;
    }

    .quotesText {
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
    }

    .articleTitle {
        margin-top: 24px;
    }

    .othersDiv {
        margin-bottom: 50px;
    }

    .progressBarDiv {
        padding: 40px 16px;
    }

    .checkpointServiceDiv {
        width: 114px;
    }
}

@media screen and (min-width:768px) and (max-width:992px) {
    .homeBottomBanner {
        padding: 16px;
    }

    .sessionBtnDiv {
        flex-direction: column;
    }

    .checkpointServiceDiv {
        width: 100px;
    }
}

@media screen and (max-width:360px) {
    .checkpointServiceDiv {
        width: 100px;
    }

    .scheduleDivBtn {
        width: 200px;
    }
    .boxMargin{
        margin-right: 25px !important;
    }
}
@media screen and (max-width:420px) { 
    .upcomingDiv{
        flex-direction: column;
    }
  

}