.languageSelect {
  border: 1px solid #a6b8c4 !important;
  width: max-content !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
}

.resourecsSerachInput {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 12px 40px !important;
  height: 45px !important;
  border: none !important;
  border-bottom: 1px solid #A6B8C4 !important;
  border-radius: 8px !important;
  color: #5A646B !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.serachIcon {
  top: 4px !important;
  left: 2px !important;
}

.resourcesFavIcon{
  position: absolute;
    bottom: 16px;
    right: 16px;
}