.desktopDisplay {
    display: block !important;
}

.profileBoxShadow {
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
    transform: translateX(-72px);
}

.pathwayHeading {
    color: var(--text-primary, #141719);
    font-size: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}

.pathwaySubHeading {
    color: var(--text-secondary, #3D464D);
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 12px;
}

.checkpointText {
    color: var(--text-green, #024742);
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-top: 16px;
}
.gridRow{
    justify-items: flex-end;
}
.gridRow:nth-child(even){  
    direction: rtl;
    /* margin-left: -80px; */
    /* justify-items: flex-end; */
}
.gridRow:nth-child(odd){ 
    /* margin-right: -80px; */
    /* justify-items: flex-end; */
}

@media screen and (max-width:768px) {
    .desktopDisplay {
        display: none !important;
    }

    .profileBoxShadow {
        box-shadow: none;
        transform: none;
    }
}