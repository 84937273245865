.react-calendar {
  width: 100% !important;
  border: 1px solid #a6b8c4 !important;
  border-radius: 16px !important;
  padding: 17px !important;
  font-family: "Inter" !important;
}

.react-calendar__navigation {
  height: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
  background: none !important;
}

.react-calendar__month-view__weekdays {
  font-weight: 400 !important;
  font-size: 12px !important;
  text-decoration: none !important;
  width: 99% !important;
  line-height: 16px !important;
  color: #141719 !important;
  margin-top: 8px !important;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #a6b8c4 !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0 !important;
  margin-top: 8px !important;
  box-sizing: content-box !important;
  margin-bottom: 8px !important;
  max-width: min-content !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #141719 !important;
}

/* TODO: need to separate out this default calendar classes */
/* Class for customising tile if expert has applied leave */
.react-calendar__viewContainer .react-calendar__tile.markOnLeave {
  border: 1px solid #f687b3;
  color: #702459;
}

.react-calendar__tile {
  font-weight: 400 !important;
  font-size: 12px !important;
  padding: 9px !important;
  margin: 0 10px !important;
  max-width: 24px !important;
  /* width: 24px !important;
  height: 24px !important; */
  box-sizing: content-box !important;
  border-radius: 99px !important;
}

.react-calendar__tile:enabled:hover {
  border-radius: 99px !important;
  background: #4299e183 !important;
}

.react-calendar__tile:enabled:focus {
  border-radius: 99px !important;
  background: #4299e1 !important;
  color: white !important;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from {
  position: absolute !important;
  left: 19px !important;
  top: -5px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.react-calendar__tile--active {
  background: #4299e1 !important;
  color: white !important;
}

.react-calendar__navigation__prev-button {
  position: absolute !important;
  right: 30px !important;
  /* top:5px !important; */
  width: 8px !important;
  cursor: pointer !important;
}
.react-calendar__navigation__next-button {
  position: absolute !important;
  /* top:5px !important; */
  right: 0px !important;
  width: 8px !important;
  height: 15px !important;
  cursor: pointer !important;
}

.react-calendar__navigation button{
  min-width: 8px !important;
  max-width: 8px !important;
  height: 15px !important;
  background: none !important;
  cursor: default ;
}



/* .react-calendar__tile:disabled {
    background-color: transparent !important;
    color: #f0f0f0;
} */

/* .react-calendar__navigation__prev-button :hover,
.react-calendar__navigation__prev-button :focus {
  background: none !important;
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__arrow:focus {
  background: none !important;
} */

.quill {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.ql-container {
  border-top: 1px solid #a6b8c4 !important;
  border-left: 1px solid #a6b8c4 !important;
  border-right: 1px solid #a6b8c4 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #5a646b !important;
}

.ql-editor {
  padding: 8px !important;
}

.ql-toolbar {
  margin-top: -4px !important;
  border-radius: 4px !important;
  border: 1px solid #a6b8c4 !important;
  border-top: none !important;
  padding-top: 10px !important;
}

.ql-formats {
  margin-top: -10px !important;
  margin-left: -10px !important;
}

.ql-header {
  display: none !important;
}

.ql-list {
  display: none !important;
}

.ql-clean {
  display: none !important;
}

.domainSimpleGrid {
  column-gap: 31.5px !important;
  row-gap: 24px !important;
  margin-top: 24px !important;
  /* grid-template-columns: 1fr 1fr 1fr !important; */
}

.notesSaveBtn {
  border-radius: 10px !important;
  padding: 0 12px !important;
  background: #4299e1 !important;
  height: 34px !important;
  color: white !important;
  position: absolute !important;
  bottom: 8px !important;
  right: 8px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.notesSaveBtn:hover {
  background: #4299e1 !important;
  color: white !important;
}

.parentJournalMapTaskName {
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.parentJournalMapSubGoal {
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
  color: #3d464d !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  text-underline-offset: 3.5px !important;
  line-height: 16px !important;
}

.sessionHistoryMapDate {
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.sessionHistoryMapUserExpert {
  font-weight: 400 !important;
  margin-top: 8px !important;
  letter-spacing: 0.4px !important;
  color: #3d464d !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.editHideOverlayBox {
  position: fixed !important;
  z-index: 1000 !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.editHideContainerBox {
  z-index: 1000 !important;
  width: 120px !important;
  background: white !important;
  border-radius: 4px !important;
  position: absolute !important;
  top: 39.44px !important;
  right: 11px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.checkpointTaskOverlayBox {
  z-index: 1000000 !important;
  width: 272px !important;
  background: white !important;
  border-radius: 4px !important;
  position: absolute !important;
  padding: 0 12px 12px 12px !important;
  top: 39.44px !important;
  right: 11px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.editHideContainerBoxText {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  cursor: pointer !important;
}

.modalHeader {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: #141719 !important;
}

.modalButtons {
  border-radius: 10px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 0 12px !important;
  height: 34px !important;
}

.modalButtons2 {
  border-radius: 10px !important;
  line-height: 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 12px !important;
  height: 34px !important;
}
.modalButtons2:active {
  background: #4299E1 !important;
}
.modalButtons2:hover{
  background: #4299E1 !important;
}
.singleCheckpointDataId {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.singleCheckpointDataLockedBtn {
  padding: 2px 12px !important;
  border-radius: 24px !important;
  background: #f3f8fb !important;
}

.singleCheckpointDataLockedBtn:hover {
  background: #f3f8fb !important;
  cursor: default !important;
}

.singleCheckpointDataLockedText {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5a646b !important;
}

.singleCheckpointDataTooltip {
  text-align: center !important;
  padding: 8px 12px !important;
  background: #141719 !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  max-width: 200px !important;
}

.singleCheckpointDataTooltipBtn {
  border-radius: 10px !important;
  padding: 10px 24px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.singleCheckpointDataMapIdBtn {
  border-radius: 24px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  text-align: center !important;
  padding: 6px 8px 5px 8px !important;
  background: #e9f6ff !important;
  cursor: pointer !important;
}

.singleCheckpointDataMapIdBtn:hover {
  background: #e9f6ff !important;
  cursor: pointer !important;
}

.singleCheckpointDataMapTaskName {
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.singleCheckpointDataMapSubGoal {
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
  color: #3d464d !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  text-underline-offset: 3.5px !important;
  line-height: 16px !important;
}

.singleCheckpointDataMapBtn {
  border-radius: 10px !important;
  padding: 6px 12px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.singleDomainCardBox {
  cursor: pointer !important;
  border: 1px solid #a6b8c4 !important;

  border-radius: 10px !important;
  padding: 16px !important;
}

.singleDomainCardBoxTitle {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
}

.singleDomainCardBoxDesc {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-top: 8px !important;
  color: #5a646b !important;
}

.singleDomainCardBoxLvlsAssigned {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5a646b !important;
}

.singleDomainCardBoxLvlsAssigned2 {
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #4299e1 !important;
}

.singleDomainCardBoxDynamicArrays {
  width: 24px !important;
  height: 24px !important;
}

.singleDomainPageBackBtn {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #4299e1 !important;
}

.singleDomainPageMapTaskName {
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.singleDomainPageMapSubGoal {
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
  color: #3d464d !important;
  font-size: 12px !important;
  text-decoration: underline !important;
  text-underline-offset: 3.5px !important;
  line-height: 16px !important;
}

.singleNoteFlex {
  position: relative !important;
  margin-top: 8px !important;
}

.singleNoteDangerousHTMLDiv {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
  max-width: calc(100vh - 80px);
}

.singleNoteDate {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #5a646b !important;
}

.singleNoteBy {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #5a646b !important;
}

.singleNoteDotFlex {
  gap: 2.75px !important;
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  height: 24px !important;
  cursor: pointer !important;
  width: 24px !important;
}

.singleNoteDotFlex:hover {
  background: #e9f6ff !important;
}

.goToTopBtn {
  position: fixed !important;
  right: 24px !important;
  padding: 8px !important;
  font-weight: 900 !important;
  color: #4299e1 !important;
  background: #e9f6ff !important;
  bottom: 17.12px !important;
  border-radius: 48px !important;
}

.pathwayHandlerOverlayUpcomingLeavesAboveBox {
  border: 2px solid #f687b3 !important;
  height: 16px !important;
  border-radius: 16px !important;
  width: 16px !important;
}

.pathwayHandlerOverlayUpcomingLeaves {
  color: #702459 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.pathwayHandlerOverlayPreferredSlotText {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
  margin-top: 16px !important;
}

.pathwayHandlerStudentNameBanner {
  gap: 8px !important;
  border-radius: 32px !important;
  margin-top: 17.12px !important;
  margin-left: 72px !important;
  align-items: center !important;
  padding: 8px 16px !important;
  background: white !important;
  height: min-content !important;
  width: max-content !important;
}

.pathwayHandlerText {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.pathwayHandlerOverflowHider {
  background: white !important;
  z-index: 5 !important;
  height: 130px !important;
  position: fixed !important;
  top: 98px !important;
}

.pathwayHandlerOverflowHiderBottomBox {
  background: white !important;
  z-index: 1000 !important;
  position: sticky !important;
  top: 144px !important;
}

.pathwayHandlerOverflowHiderBottomBoxEmptyBox {
  width: 100vw !important;
  position: sticky !important;
  background: white !important;
  z-index: 1000 !important;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.16) !important;
  transform: translateX(-72px) !important;
}

.tableHeader {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Inter" !important;
  letter-spacing: 0.15px !important;
  color: #141719 !important;
  padding: 16px !important;
  border-bottom: 1px solid #d8efee !important;
}

.tableData {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: "Inter" !important;
  color: #141719 !important;
  padding: 16px !important;
  border: none !important;
}

.tableStudentAvatar {
  height: 32px !important;
  width: 32px !important;
  background-size: contain !important;
}

.myStudentIndexSecondBoxMainFlexContainer {
  padding: 24px 0 !important;
  justify-content: start !important;
}

.createPathwayBtn {
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  height: 43px !important;
  border-radius: 10px !important;
  background: #4299e1 !important;
  border: none !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
}

.servicesMainBodyContainerForSessionTab {
  border-bottom: 1px solid #d8e5ee !important;
  border-radius: 0 !important;
  padding: 16px !important;
  margin-top: 0 !important;
}
@media screen and (max-width:360px) {
  .react-calendar__tile {
    margin: 0 1px !important;
    padding: 5px !important;
    font-size: 10px !important;
  }
}
@media screen and (max-width:767px) {
  .react-calendar__tile {
    margin: 0 1px !important;
    padding: 5px;
  }
}
