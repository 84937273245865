.feedbackExpertBlock{
    color:  #5A646B;
    text-align: center;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    border-radius: 5px;
    background: #E9F6FF;
    padding: 15px;
}
.thankYouText{
    color:  #141719;
    font-family:"Inter",sans-serif;
    font-size: 28.56px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 35.7px; /* 125% */
    margin-top: 25px;
}
.thankYouSubText{
    margin-top: 8px;
    margin-bottom: 8px !important;
    color:  #141719;
    text-align: center;
    font-family: "Inter",sans-serif;
    font-size: 16.065px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.42px; /* 133.333% */
}