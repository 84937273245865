/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

/* input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

.pendingBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 180px 0;
  justify-content: center;
}

.pendingBoxText {
  font-weight: 600 !important;
  font-family: "Inter";
  font-size: 24px !important;
  margin: 32px 0 16px 0;
}

.personalGrid {
  font-family: "Inter";
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
}

.personalImageValidators {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Inter" !important;
  color: "#5A646B" !important;
  line-height: 16px !important;
  margin-top: 14px !important;
}

.personalSimpleGrid {
  row-gap: 24px;
  column-gap: 40px;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
}

.customPhoneInput {
  border: none !important;
  outline: none !important;
  height: 92.7% !important;
  margin-top: 0.813px !important;
  margin-left: 1.2px !important;
  padding-top: 3px !important;
}
.myProfilCustomPhoneInput {
  border: none !important;
  outline: none !important;
  height: 94.7% !important;
  margin-top: 0.813px !important;
  margin-left: 1.2px !important;
  padding-top: 3px !important;
}

.customPhoneInput .flag {
  scale: 1.5 !important;
}

.servicesSimpleGrid {
  row-gap: 24px;
  column-gap: 40px;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
}

.personalFormLabel {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter";
  line-height: 20px;
  color: #141719;
}

.servicesFormLabel {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter";
}

.qualificationsFormLabel {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter";
}

.personalFormInput {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  padding: 0 12px !important;
  line-height: 20px !important;
}

.myProfileFormInput {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: left !important;
  font-family: "Inter";
  padding: 0 12px !important;
  line-height: 20px !important;
  border-radius: 4px !important;
}

.personalShortBio {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  padding: 10px !important;
  line-height: 20px !important;
  padding-bottom: 24px !important;
}

.personalPhoneInput {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  padding-left: 95px !important;
  line-height: 20px !important;
}

.servicesFormInput {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  padding: 0 12px !important;
  line-height: 20px !important;
}

.qualificationsFormInput {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  padding: 0 12px !important;
  line-height: 20px !important;
  border: 1px solid #a6b8c4;
}

.commonFormSelect {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 20px !important;
  text-align: start !important;
}

.timeSlotSelect {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 20px !important;
  text-align: start !important;
}

.commonFormSelect span {
  border-radius: 99px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 4px 12px !important;
  background-color: #f3f8fb !important;
}

.commonFormSelect div {
  font-weight: 400 !important;
  line-height: 20px !important;
  gap: 8px !important;
  border: none !important;
  /* background-color: white; */
}

.personalErrorMessage {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 16px !important;
  color: #ba1a1a;
  text-align: left;
}

.servicesErrorMessage {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 16px !important;
  color: #ba1a1a;
}

.qualificationsErrorMessage {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 16px !important;
  color: #ba1a1a;
}

.qualificationsMainText {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter";
  line-height: 24px !important;
  margin-top: 10px;
}

.qualificationsDeleteBtn {
  background: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter";
  line-height: 24px !important;
  color: #ba1a1a !important;
}

.qualificationsCheckBox {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
  line-height: 20px !important;
}

.workingHoursBox1 {
  cursor: pointer;
  text-align: start;
  border: 1px solid #a6b8c4;
  font-weight: normal !important;
  border-radius: 4px !important;
}

.workingHoursGridItemFlex1 {
  background: #f3f8fb;
  padding: 16px !important;
  align-items: center;
  color: #5a646b;
  text-align: start;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-size: 14px !important;
}

.workingHoursSimpleGrid1 {
  gap: 24px !important;
  margin-top: 24px !important;
  align-items: center !important;
  justify-content: center !important;
}

.workingHoursMyProfileSimpleGrid1 {
  align-items: center !important;
  justify-content: center !important;
}

.workingHoursTimeSlotBox1 {
  border: 1px solid #a6b8c4;
  font-weight: normal !important;
  text-align: start !important;
}

.workingHoursMyProfileTimeSlotBox1 {
  border: 1px solid #a6b8c4;
  padding: 0 12px !important;
  border-radius: 4px !important;
  font-weight: normal !important;
  text-align: start !important;
}

.personalBioWordsRemaining {
  color: "#5A646B" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-family: "DM Sans" !important;
  position: absolute !important;
  bottom: 10px;
  right: 12px;
  z-index: 2;
}

.workingHoursTimeZone {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: start !important;
  margin-bottom: 6px !important;
}

.workingHoursTimeZoneData {
  font-family: "Inter" !important;
  text-align: start !important;
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 28px !important;
}
.country {
  display: flex;
  align-items: flex-start;
}
