.layoutBottomBanner{
    /* background-image: url(../../assets/images/authBottomBg.svg);
    background-position:left bottom;
    background-repeat: no-repeat;
    height: 245px; */
    position: fixed;
    bottom: 0;
}

.loginHeading{
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 28px !important;
    color: #141719 !important;
    text-align: center;
}
.loginSubHeading{
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #5A646B !important;
}
.loginBtn{
    padding: 10px 20px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #F3F8FB !important;
    border-radius: 10px !important;
    border: none;
    color: #91A2AE !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.secondLoginBtn{
    padding: 10px 20px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    border: 1px solid #4299E1;
    color: #4299E1 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.thirdLoginBtn{
    padding: 10px 20px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    border: 1px solid #A6B8C4;
    color: #141719 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}