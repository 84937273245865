.mainScheduleBox {
  border: 1px solid #d8e5ee;
  border-radius: 8px;
  background-color: #ffffff;
}
.calenderTopBox {
  border-bottom: 1px solid #d8e5ee;
  border-top: 1px solid #d8e5ee;
  min-height: 64px;
  padding: 16px;
}
.customSelect {
  border: 1px solid #a6b8c4 !important;
  border-radius: 10px !important;
  /* width: 10% !important; */
}
.customSelect:focus {
  box-shadow: none !important;
  border: 1px solid #a6b8c4 !important;
}
.monthName {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #141719;
  padding: 0 16px;
  text-align: center;
}
.weekText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #141719;
  background: #f3f8fb;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
}
.goToDiv {
  border: 1px solid #a6b8c4;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #141719;
  padding: 6px 12px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  width: 103px;
  cursor: pointer;
}
.react-calender-border{
  border: none !important;
}
.calenderImg {
  margin: 0px 0px 0px 8px !important;
}
.calenderDateCol {
  padding: 12px 14px;
  display: flex;
  border-right: 1px solid #d8e5ee;
}
.dateNoCss {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3d464d;
  margin-right: 24px;
  margin-left: 8px;
}
.activeDateCss {
  background: #4299e1;
  border-radius: 50%;
  width: 35px;
  height: 30px;
  padding: 4px;
  color: #ffffff;
  margin-right: 10px !important;
  margin-left: 0px;
  text-align: center;
}
.monthDayText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3d464d;
  margin: 2px 0px;
}
.sessionTime {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3d464d;
  margin-left: 8px;
  width: 110px;
}
.sessionExpertName {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #141719;
  margin-left: 24px;
}
.sessionTypeTag {
  border: 1px solid #d8e5ee;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d464d;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.updateACRTag {
  background: #ffdad6;
  border-radius: 20px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #410002;
}
.sessionExpertNameDiv {
  display: flex;
  padding: 12px 12px 12px 14px;
  align-items: flex-start;
  min-height: 48px;
}
.sessionTypeTagDiv {
  display: flex;
  justify-content: end;
  padding: 12px;
  min-height: 48px;
}
.sessionRequestTag {
  background: #fedfa0;
  border-radius: 20px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #734110;
}
.noShowTag {
  background: #ffffff;
  border: 1px solid #ba1a1a;
  border-radius: 20px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ba1a1a;
}
.joinedTag {
  background: #ffffff;
  border: 1px solid #4299E1;
  border-radius: 20px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4299E1;
}
.tooltipCss {
  background-color: #141719 !important;
  border-radius: 2px !important;
  padding: 8px 12px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  text-align: center !important;
}
