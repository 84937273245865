.editProfileTitle{
    color: #141719;
    font-family: 'Inter',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 16px !important;
}
.overlayComponentContentBoxFlexParent {
    justify-content: space-between !important;
    border-bottom: 1px solid #a6b8c4 !important;
}
.overlayComponentContentBoxParent {
    position: fixed !important;
    z-index: 70000000 !important;
    top: 0 !important;
    width: 460px !important;
    right: 0 !important;
    background: white !important;
    bottom: 0 !important;
    min-height: 100vh !important;
    opacity: 100% !important;
    max-height: fit-content;
  }
  .parentEditProfileHeading {
    color:  #141719;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.15px;
    text-align: left;
  }
  .checkboxGroup{
    text-align: left !important;
  }
  .react-tel-input{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #91A2AE;
    border-radius: 4px;
    border: 1px solid  #A6B8C4;
  }
  .react-tel-input .form-control {
    width: 100% !important;
    border: none !important;
  }
  .react-tel-input .selected-flag {
    border: none !important;
    border-right:  1px solid  #A6B8C4;
    background-color: #F3F8FB !important;
  }
  .profileFormSelect {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "Inter";
    line-height: 20px !important;
    text-align: start !important;
    width: 100%;
  }
  /* .profileFormSelect span {
    border-radius: 99px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 4px 12px !important;
    background-color: #f3f8fb !important;
  }
   */
  /* .profileFormSelect div {
    font-weight: 400 !important;
    line-height: 20px !important;
    gap: 8px !important;
    border: none !important;
    /* background-color: white; */
    @media screen and (max-width:767px) {
      .overlayComponentContentBoxParent {
         width: 100% !important;
      }
}