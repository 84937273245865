.paymentDetailsModalHeader{
    display: flex;
    align-items: center;
    font-family: "Inter",sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0.15px !important;
    color: #141719 !important;
}
.paymentDetailsText{
    color:  #1A365D;
    font-family: "Inter",sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}