.mainDiv{
    background-color: #FCFAF4;
    padding: 72px;
    text-align: center;
}
.formHeading{
    font-family:"Inter",sans-serif;
    font-size:24px ;
    font-weight: 600;
    color: #141719;
    text-align: center;
    margin-bottom: 24px !important;
}
.sidebarLeft {
    padding: 24px  !important;
    margin-top: 100px;
  }
  
.sidebar {
    padding: 24px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0.1px !important;
    color: #5A646B !important;
    background-color: #FFFFFF;
    border-radius: 20px !important;
    text-align: center;
  }
  .activeTabACR{
   
    border:2px solid #4299E1;
    
  }
  .inActiveTabACR{
    
    border: 1px solid #D8E5EE;
  
  }

  .specialistName{
    font-family:"Inter",sans-serif;
    font-size:24px ;
    font-weight: 600;
    color: #141719;
    text-align: left;
}
.specialistDiv{
    background-color: #FFFFFF;
    border: 1px solid #D8E5EE;
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 24px;
    
}
.dateHeading{
    font-family:"Inter",sans-serif;
    font-size:16px ;
    font-weight: 400;
    color: #5A646B;
    text-align: left;
    min-width: 70px
}
.datevalues{
    font-family:"Inter",sans-serif;
    font-size:16px ;
    font-weight: 500;
    color: #141719;
    text-align: left;
    margin-left: 8px;
}
.acrFormLabel{
    font-family:"Inter",sans-serif;
    font-size:14px ;
    font-weight: 500;
    color: #141719;
    text-align: left; 
   
}

.tabHeading{
        font-family:"Inter",sans-serif;
        font-size:18px ;
        font-weight: 500;
        line-height: 24px;
        color: #141719;
        text-align: left;          
}
.childPresentTab{
    border:1px solid #D8E5EE;
    border-radius: 25px;
    padding: 4px;
    justify-content: space-around;
    font-family:"Inter",sans-serif;
    font-size:14px ;
    font-weight: 600;
    line-height: 20px;
    color: #5A646B !important;
    min-height: 40px;
    align-items: center;
}
.genderTab{
align-items: center;
justify-content: center;
}
.childPresentTabActive{
    color:#4299E1 !important ;
    background-color: #E9F6FF !important;
    border-radius: 25px;
    padding: 5px 25px;
    /* min-width: max-content; */
}
.childPresentTabInActive{
    padding: 5px 25px;
    /* min-width: max-content; */
}
.acrFormInput {
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: left !important;
    font-family: "Inter";
    padding: 0 12px !important;
    line-height: 20px !important;
    border-radius: 25px !important;
    border:1px solid #D8E5EE ;
  }
  
.subHeading2{
    font-family:"Inter",sans-serif;
    font-size:14px ;
    font-weight: 500;
    line-height: 24px;
    color: #5A646B;
    text-align: left !important;   
    text-transform: uppercase;
    margin-top: 24px;
   
}
.acrFormSelect {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "Inter";
    line-height: 20px !important;
    text-align: start !important;
    width: 100%;
    min-width: 102px;
  }
  .acrFormSelect span {
    border-radius: 99px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 4px 12px !important;
    background-color: #f3f8fb !important;
    border-radius: 25px;
  }
  
  .acrFormSelect div {
    font-weight: 400 !important;
    line-height: 20px !important;
    gap: 8px !important;
    /* border:1px solid #D8E5EE !important; */
    border-radius: 25px;
  
  }

  .acrFormSelect::-webkit-scrollbar { 
    width: 12px; 
} 

.acrFormSelect::-webkit-scrollbar-track { 
    background: #f1f1f1; 
} 

.acrFormSelect::-webkit-scrollbar-thumb { 
    background: #888; 
} 

.acrFormSelect::-webkit-scrollbar-thumb:hover { 
    background: #555; 
} 

.acrFormSelect::-webkit-scrollbar-button { 
    display: none; 
} 

.medicalIssuesTabs{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    gap: 8px !important;
    color: #5A646B !important;
    border-radius: 25px;
    border: 1px solid #D8E5EE !important;
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
 
}
.medicalIssuesTabsActive{
    color: #3EB274 !important;
}
.medicalIssuesTabsFlex{
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
 
}
.observationsHeading{
    font-family:"Inter",sans-serif;
    font-size:14px ;
    font-weight: 600;
    color: #141719;
    text-align: center; 
    margin-bottom: 16px !important;
}
.observationsDesc{
    font-family:"Inter",sans-serif;
    font-size:14px ;
    font-weight: 400;
    color: #5A646B;
    text-align: left; 
    margin-bottom: 16px !important;
}
.saveBtn {
    padding: 8px 16px !important;
    border-radius: 40px !important;
    width:150px;
  }
  .tipsBox{
    border:1px solid #0F755C;
    background-color: #EFF7E6;
    padding: 16px;
    font-family:"Inter",sans-serif;
    font-size:14px ;
    line-height: 20px;
    font-weight: 500;
    color: #0F755C;
    text-align: left; 
    border-radius: 25px;
    width: 230px;
  }

@media screen and (max-width:992px) {
    .sidebarLeft{
        margin-top: 20px;
    }
}