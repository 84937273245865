.sesssionBalanceDiv{
    border-radius: 16px;
    border: 1px solid #D8E5EE;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.balanceTitle{
    color: #141719;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    width:140px;
}
.balanceCountText{
    color: #141719;
    text-align: center;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
}
.sesssionJoinBtn{
    border-radius: 10px !important;
    background:  #4299E1 !important;
    color:  #FFF !important;
    font-family: 'Inter',sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important; /* 142.857% */
    text-transform: capitalize;
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px !important;
    justify-content: center !important;
    align-items: center !important;
}
.sesssionOtherBtns{
    border-radius: 10px !important;
    border: 1px solid #A6B8C4 !important;
    background:  #FFF !important;
    color:  #141719 !important;
    font-family: 'Inter',sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important; /* 142.857% */
    text-transform: capitalize;
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px !important;
    justify-content: center !important;
    align-items: center !important;
}
.sessionBtnsDiv{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap:8px;
}

.sessionsIndexSecondBoxMainFlexContainer {
    padding: 24px 16px !important;
    justify-content: start !important;
  }
 
  .sessionsIndexSecondBoxFirstMapText {
    padding: 10px 16px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0.1px !important;
  }
  .activeTab{
    background-color:#E9F6FF;
    border:#E9F6FF;
    color:#2b6cb0;
  }
  .inActiveTab{
    background-color:"white" ;
    border: 1px solid #A6B8C4;
    color:#5a646b;
  }
  .parentSessionCountDiv {
    border: 1px solid #D8E5EE;
    border-radius: 8px;
    padding: 24px;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.sessionsSecondMapFlex {
    border: 1px solid #d8e5ee !important;
    border-radius: 4px !important;
    padding: 12px !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 8px !important;
    min-height: 68px !important;
  }

  @media screen and (max-width:768px) {
    .sessionsIndexSecondBoxMainFlexContainer {
        padding: 0px !important;
        justify-content: space-around !important;
        border-bottom: 1px solid var(--components-divider, #D8E5EE);
        margin-left: -16px;
        margin-right: -16px;
      }
      .sessionsIndexSecondBoxFirstMapText{
        padding: 10px 0px !important; 
        /* border-radius: 4px; */
        background-color:#ffffff !important;
        
      }
      .activeTab{
        border:unset;
        color:#4299E1;
        border-radius:0px !important;
        border-bottom: 4px solid #4299E1;
      }
      .inActiveTab{
        border: unset;
        color:#5a646b;
      }
      .parentSessionCountDiv {
        margin-top: 16px;
        padding: 16px;
    }
    .sessionsSecondMapFlex{
        flex-direction: column;
        gap:24px;
        align-items: flex-start !important;
    }
  }
  @media screen and (max-width:420px) {
    .sessionBtnsDiv{
     margin-top: 8px;
  }

  }

