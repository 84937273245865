.bottomNavbar{
    background: #FFFFFF;
    border-top: 1px solid #D8E5EE;
    min-height: 68px;
    padding: 14px 25px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
}
.navbarText{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #3D464D;
    margin-top: 8px;
    text-align: center;
}
.navbarItemsDiv{
    display:flex; 
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:300px) {
 
    .bottomNavbar {
      
      padding:14px 0px !important;
    }
}