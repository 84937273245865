.loaderMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 325px );
}
.loaderText{  
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5A646B;
    margin-top: 24px;
}

.mobileNotAllowedHeading{  
    font-family: "Inter",sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #141719;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.mobileNotAllowedSubText{
   
font-family: "Inter",sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
text-align: center;
color: #5A646B;
margin-top: 20px;
margin-left: 10px;
    margin-right: 10px;
}