.expertName{
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #141719 !important;
}
.expertNameBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.subHeading{
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #5A646B !important;
    margin-top: 8px !important;
}
.statDescText{
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.1px !important;
    color: #141719 !important;
}
.statCountText{
    font-weight: 500 !important;
    font-size: 45px !important;
    line-height: 52px !important;
    text-align: center !important;
    color: #141719 !important;
}
.statBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D8E5EE;
    border-radius: 16px;
    padding: 16px;
}
