.popupHeader{
    color:  #141719;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.15px;
    text-align: left;
    padding: 20px 16px 8px 16px;
   
}
.popupBody{
    color: #141719;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-align: left;
    padding: 0 16px 0px 16px;
   
}