body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 0 !important;
}
h2 {
  margin-bottom: 0 !important;
}
.inputLabel {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
}
/* separator */
.separator {
  display: flex;
  flex-direction: row;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91a2ae;
}

.separator::before {
  margin-right: 16px !important;
}

.separator::after {
  margin-left: 16px !important;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1 1;
  margin: auto;
  border-bottom: 1px solid #91a2ae !important;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background: url("/public/CalendarBlank.svg");
  background-size: contain;
}

.form-control:disabled {
  background-color: #F3F8FB !important;
}
.myProfileFormInput:disabled{
  background-color: #daecf5 !important;
}