.outcomeModalHeader{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #141719;
    margin-bottom: 8px;
}
.outcomeModalSubHeading{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #141719;
    margin-bottom: 24px;
}
.outcomeModalButton{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #141719;
    background: #FFFFFF !important;
    border: 1px solid #A6B8C4;
    border-radius: 10px;
}