.myProfileIndexAvatar {
  height: 96px !important;
  width: 96px !important;
  transform: translateY(-65px) !important;
}

.myProfileIndexText {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #141719 !important;
}

.myProfileIndexIcons {
  height: 16px !important;
  width: 16px !important;
  margin-top: 2px !important;
}

.myProfileIndexIconsText {
  color: #5a646b !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.myProfileIndexFirstBoxButtonFlex {
  gap: 16px !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.myProfileIndexFirstBoxButtonFlexBtns {
  color: #141719 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  border-radius: 10px !important;
  background: white !important;
  border: 1px solid #4299e1 !important;
}

.leavesApplyLeaveBtn {
  color: #141719 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  height: 43px !important;
  border-radius: 10px !important;
  background: white !important;
  border: 1px solid #a6b8c4 !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
}

.myProfileIndexSecondBoxMainFlexContainer {
  padding: 24px 16px !important;
  justify-content: start !important;
}

.myProfileIndexSecondBoxFirstMapText {
  padding: 10px 16px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
}
.activeTab{
  background-color:#E9F6FF;
  border:#E9F6FF;
  color:#2b6cb0;
}
.inActiveTab{
  background-color:"white" ;
  border: 1px solid #A6B8C4;
  color:#5a646b;
}
.chakra-select__icon-wrapper {
  color: #4299e1 !important;
}

.txnsTabText1 {
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 36px !important;
  color: #141719 !important;
}

.txnsTabText2 {
  margin-top: 8px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  /* line-height: 8px !important; */
  color: #3d464d !important;
  /* margin-bottom: 16px !important; */
}

.txnsTabSelect {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
  border: 1px solid #a6b8c4 !important;
  border-radius: 10px !important;
  /* height: 40px !important; */
  /* width: 100%; */
}

.workingHourTabSelect {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
  border: 1px solid #a6b8c4 !important;
  border-radius: 4px !important;
  /* height: 40px !important; */
}

.leavesTabSelect {
  font-weight: 400 !important;
  font-size: 14px !important;
  width: 100% !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  border: 1px solid #a6b8c4 !important;
  color: #a6b8c4 !important;
}

.txnsFlex2Box1 {
  padding: 16px 24px !important;
  width: 100% !important;
  height: 132px !important;
  border-radius: 16px !important;
  border: 1px solid #d8e5ee !important;
}

.txnsFlex2Box1Box1 {
  line-height: 24px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: 0.15px !important;
}

.txnsFlex2Box1Box1Text1 {
  font-weight: 600 !important;
  font-size: 45px !important;
  line-height: 52px !important;
  text-align: center !important;
}

.txnsFlex2Box1Box1Text2 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.txnsFlex2Box1Box1Text2span {
  font-size: 24px !important;
  line-height: 32px !important;
  text-align: center !important;
}

.txnsFirstMapBoxText {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #3d464d !important;
  margin-top: 24px !important;
}

.txnsSecondMapFlex {
  border: 1px solid #d8e5ee !important;
  border-radius: 4px !important;
  padding: 12px !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top: 8px !important;
  min-height: 68px !important;
}

.servicesMainBodyContainer {
  border: 1px solid #d8e5ee !important;
  border-radius: 8px !important;
  padding: 16px !important;
  margin-top: 24px !important;
}

.pathwayCreationMainBodyContainer {
  border: 1px solid #d8e5ee !important;
  border-radius: 8px !important;
  padding: 16px !important;
  margin-top: 16px !important;
}

.pathwayCreationOutcomeMainBodyContainer {
  border: 1px solid #d8e5ee !important;
  border-radius: 4px !important;
  padding: 12px !important;
}

.myStudentsIndividualTaskContainer {
  border: 1px solid #d8e5ee !important;
  border-radius: 4px !important;
  padding: 12px !important;
  margin-top: 16px !important;
}

.txnsSecondMapFlexBoxFlexText1 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: #141719 !important;
}

.txnsSecondMapFlexBoxFlexText2 {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: 1px solid #d8edee !important;
  padding: 2px 12px !important;
  border-radius: 20px !important;
  margin-left: 16px !important;
  color: #141719 !important;
  text-transform: capitalize;
}

.leavesStatus {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: 1px solid #d8edee !important;
  padding: 2px 12px !important;
  border-radius: 20px !important;
  color: #141719 !important;
}

.txnsSecondMapFlexBoxFlexFlex1 {
  font-weight: 400 !important;
  margin-top: 8px !important;
  gap: 8px !important;
  font-size: 12px !important;
  letter-spacing: 0.4px !important;
  line-height: 16px !important;
  color: #3d464d !important;
}

.txnsSecondMapFlexBoxTag {
  /* height: 100% !important; */
  border-radius: 20px !important;
  padding: 2px 12px 2px 8px !important;
}

.txnsSecondMapFlexBoxTagAvatar {
  height: 16px !important;
  margin-right: 8px !important;
  width: 16px !important;
}

.servicesMainBodyTitle {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  margin-bottom: -15px !important;
}

.servicesMainBodyTitleDesc {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;

}

.leavesApplierFlex {
  background: #e9f6ff !important;
  padding: 16px !important;
  gap: 8px !important;
  align-items: start !important;
}

.leavesApplierFlexText1 {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #1a365d !important;
}

.leavesApplierFlexFormLabel {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141719 !important;
}

.leavesApplierFlexFormInput {
  border-radius: 4px !important;
  padding: 0 12px !important;
  height: 43px !important;
  color: #141719 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: 1px solid #a6b8c4 !important;
}

.leavesApplierFlexFormInputError {
  padding: 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #ba1a1a !important;
  text-align: center !important;
}

.leavesApplierFlexReadPolicy {
  padding: 6px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #4299e1 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  text-underline-offset: 3.2px !important;
  width:fit-content !important;
}

.overlayComponentMainBox {
  position: absolute !important;
  z-index: 50000000 !important;
  top: 0 !important;
  width: 100vw !important;
  right: 0 !important;
  background: black !important;
  bottom: 0 !important;
  min-height: 100vh !important;
  opacity: 38% !important;
}
.overlayComponentMainBox1 {
 z-index: 0 !important;
}

.overlayComponentContentBox {
  position: fixed !important;
  z-index: 70000000 !important;
  top: 0 !important;
  width: 560px !important;
  right: 0 !important;
  background: white !important;
  bottom: 0 !important;
  min-height: 100vh !important;
  opacity: 100% !important;
  max-height: fit-content;
}
.overlayComponentContentBox1 {
  position: fixed !important;
  /* z-index: 0 !important; */
  top: 0 !important;
  width: 560px !important;
  right: 0 !important;
  background: white !important;
  bottom: 0 !important;
  min-height: 100vh !important;
  opacity: 100% !important;
  max-height: fit-content;
}

.overlayComponentContentBoxFlex {
  justify-content: space-between !important;
  border-bottom: 1px solid #a6b8c4 !important;
}

.overlayComponentContentBoxFlexTitle {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  padding: 16px !important;
  color: #141719 !important;
}

.overlayComponentContentBoxFlexImage {
  padding: 16px !important;
  cursor: pointer !important;
}

.overlayComponentContentBoxChildren {
  right: 0 !important;
  left: 0 !important;
  bottom: 80px !important;
  top: 61px !important;
  position: absolute !important;
  overflow-y: scroll !important;
}

.overlayComponentContentBoxBtnsFlex {
  position: absolute !important;
  justify-content: space-between !important;
  right: 0 !important;
  padding: 16px !important;
  background: white !important;
  left: 0 !important;
  bottom: 0 !important;
  border-top: 1px solid #a6b8c4 !important;
}

.overlayComponentContentBoxBtn1 {
  padding: 10px 20px !important;
  border-radius: 10px !important;
  background: white !important;
  color: #4299e1 !important;
}

.overlayComponentContentBoxBtns:hover {
  background: white !important;
}

.overlayComponentContentBoxBtn2 {
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

.leavesMapFlex {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  color: #141719 !important;
  gap: 8px !important;
}

.leavesMapFlexTextCalculateDays {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5a646b !important;
}

.leavesMapFlexTextLeaveReason {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: #000000 !important;
  margin-top: 8px !important;
  overflow-wrap: break-word;
  word-break: break-all;
}

.workingHoursInfoTextBox {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #1a365d !important;
}

.workingHoursMyProfileSimpleGrid1 {
  column-gap: 16px !important;
  row-gap: 24px !important;
  padding: 0 16px !important;
}

.workingHourTag {
  margin-top: 24px !important;
  background: white !important;
  color: #2b6cb0 !important;
  cursor: pointer !important;
  border: 1px solid #63b3ed !important;
  margin-left: 16px !important;
  padding: 8px 16px 8px 8px !important;
}

.indexTag {
  margin-bottom: 16px !important;
  background: white !important;
  color: #2b6cb0 !important;
  cursor: pointer !important;
  border: 1px solid #63b3ed !important;
  margin-left: 16px !important;
  padding: 8px 16px 8px 8px !important;
}

.indexEditProfileText1 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  padding: 16px 16px 0 16px !important;
}

.indexEditProfileQualificationsDataMapBox {
  border-radius: 8px !important;
  border: 1px solid #a6b8c4 !important;
  margin: 24px 16px !important;
  padding: 15px 16px !important;
}

.indexEditProfileQualificationsDataMapBoxText {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5a646b !important;
}

.indexEditProfileQualificationsDataMapBoxImage {
  cursor: pointer !important;
  height: 24px !important;
  width: 24px !important;
}

.indexEditProfileQualificationsDataMapBoxInput {
  border: 1px solid #a6b8c4 !important;
  padding: 8px !important;
  border-radius: 0 !important;
}


@media screen and (max-width:768px) {
.txnsSecondMapFlex{
  flex-direction: column;
  gap:24px;
  align-items: flex-start !important;
}
.txnsTabSelect{
  width: 100%
}
.myProfileIndexSecondBoxMainFlexContainer{
  padding: 0px !important;
  justify-content: space-around !important;
  border-bottom: 1px solid var(--components-divider, #D8E5EE);
  margin-left: -16px;
  margin-right: -16px;
}
.myProfileIndexSecondBoxFirstMapText {
  padding: 10px 0px !important; 
  /* border-radius: 4px; */
  background-color:#ffffff !important;
}
.activeTab{
  border:unset;
  color:#4299E1;
  border-radius:0px !important;
  border-bottom: 4px solid #4299E1;
}
.inActiveTab{
  border: unset;
  color:#5a646b;
}
}
@media screen and (max-width:767px) {
.myProfileIndexFirstBoxButtonFlex {
  width: 100%;
}
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
} */

.txnsTabSelect:focus-visible{
  box-shadow: none !important;
} 