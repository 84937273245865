.exploreText{
    color:  #141719;
    font-family: "Inter",sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
}
.headingBlock{
    padding: 20px 72px !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D8E5EE;
}

.ageDropDown{   
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: #141719 !important;
        border: 1px solid #a6b8c4 !important;
        border-radius: 10px !important;
        /* height: 40px !important; */
        /* width: 100%; */  
}

.ageDropDown:focus-visible{
    box-shadow: none !important;
  } 

.topicHeading{
    color:  #121314;
    font-family: "Inter",sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    margin-bottom: 24px !important;
}
.exploreGrid{
    overflow: hidden;
    display: flex;
    margin: 0px 48px 48px 48px;
    /* white-space: nowrap; */
}
.exploreSubText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 26px;
    text-align: left;
    color: #5A646B;
    margin-top: 8px;
   
}
.exploreArticleTitle{
    color: #141719;
    font-family: "Inter",sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; 
    margin-bottom: 24px !important;
}
.exploreArticleCategory{
    font-style: normal;
    font-family: "Inter",sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color:#4299E1;
    text-transform: uppercase;
    margin-bottom: 5px !important;
}
 .description,
 .description>p,  .description>p>span,
 .description>div,  .description>h1,
.description>h2,  .description>h3,
 .description>h4,  .description>h5,
 .description>h6 {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #5A646B !important;
    line-height: 24px;
    /* display: -webkit-box;
    max-width: 100%;
    max-height: 100px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal !important;
    margin-bottom: 0px; */
}


.articleInfo>p, .articleInfo>ul, .articleInfo>ol, .articleInfo>dl, .articleInfo>table, .articleInfo>div, .articleInfo>span {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: #787878;
}

.articleInfo {
    overflow-x: scroll;
}

.articleInfo::-webkit-scrollbar {
    /* width: 6px;
    height: 4px;
     */
     display: none;
}

/* align image within container  */
.articleInfo>p>img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}


.articleInfo>a {
    color: #4299E1 !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.articleInfo>p>a {
    color: #4299E1 !important;
    text-decoration: underline !important;
    cursor: pointer;
    overflow-wrap: break-word;
}
.articleInfo>a:hover {
    color: #2B6CB0;
}

.articleInfo>h3, .articleInfo>h4, .articleInfo>h5, .articleInfo>h1, .articleInfo>h2 {
    font-style: normal;
    font-weight: 700;
    font-size: var(--app-fs-normal);
    color: var(--app-color-black);
    line-height: 35px;
}
@media screen and (max-width:768px) {
    .headingBlock{
        padding: 20px !important;
       
    }
    .exploreGrid{
        overflow: hidden;
       
        margin: 0px 0px 48px 48px;
        /* white-space: nowrap; */
    }
     
}
@media screen and (max-width:300px) {
   
    .exploreGrid{
       
        margin: 0px 0px 48px 0px;
        /* white-space: nowrap; */
    }
     
}