.tabText{
    color:  #141719;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    padding: 16px;
    cursor: pointer;
}
.activeTabText{
    color:  #4299E1;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    padding: 16px;
    border-bottom: 3px solid  #4299E1;
    cursor: pointer;
}
.tabsDiv{
    border-bottom: 1px solid #A6B8C4;
    justify-content: space-around;
}
.historyTabDate{
    color:  #141719;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
}
.historyTabName{
    color:  #3D464D;
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;
    margin-top: 8px;
}
.historyTabDiv{
    padding: 12px;
    border-bottom: 1px solid #D8E5EE;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sessionoverlayChildren {
    height: calc(100vh - 115px);
    overflow-y: scroll !important;
  }

  .sessionDetailsExpert{
    color:  #141719;
    font-family: "Inter",sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
    margin-top: 16px;
    margin-bottom: 8px !important;
  }

  .sessionDetailsDate{
    color: #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
  }
  
  .sessionDetailsHeading{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; 
    margin-bottom: 8px !important;
  }
  .sessionDetailsChildName{
    color:  #141719;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    text-transform: capitalize;
  }
  .sessionDetailsChildAge{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
  }
  .sessionDetailsProfileLink{
    color:  #4299E1;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    text-transform: capitalize;
    cursor: pointer;
  }