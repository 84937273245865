.pricingPlanCard{
    background: #FFFFFF;
    border: 1px solid #D8E5EE;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 14px;
    padding: 24px;
}
.popularDiv{
    display: flex;
    justify-content: space-between;
}
.discountDiv{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #5A646B;
    background: #F3F8FB;
    border-radius: 99px;
    padding: 8px 16px;
    width: max-content;
}
.priceText{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #141719;
    margin: 8px 0;
}
.pricingDiscountText{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: line-through;
    color: #5A646B;
    margin-left: 16px;
}
.pricingValidityDiv{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #5A646B;
    margin-bottom: 32px;
}
.pricingCols{
    /* justify-content: center; */
    align-items: center;
    display: flex;
}
.mb16{
    margin-bottom: 16px;
}
.pricingDetailsText{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141719;
    margin-left: 8px;
}

.LearnMoreBtn{
    background: #FFFFFF !important;
    border: 1px solid #4299E1;
    border-radius: 12px !important;
    width: 100%;
    min-height: 52px;
    min-width: max-content !important;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #4299E1 !important;
    padding: 16px 32px;
    margin-top: 24px;
}

.LearnMoreBtn:hover {
    background: #2b6cb0 !important;
    border-color: #2b6cb0 !important;
    color: #FFFFFF !important;
}
  
.LearnMoreBtn:active, .LearnMoreBtn:focus {
    background: #FFFFFF;
    color: #4299E1;
    border-color: #4299e1b0;
}
  
.activeLearnMoreBtn{
    background: #4299E1 !important;
    border-radius: 12px !important;
    width: 100%;
    min-height: 52px;
    min-width: max-content !important;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    padding: 16px 32px;
    margin-top: 24px;
  }

  .activeLearnMoreBtn:hover {
    background: #2b6cb0 !important;
    border-color: #2b6cb0 !important;
  }
  
  .activeLearnMoreBtn:active, .activeLearnMoreBtn:focus {
    background: #4299e1;
    border-color: #4299e1b0;
  }