.feedbackMainDiv{
   background-color: #E9F6FF;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;
   background-image: url("../../assets/icons/light.svg"),url("../../assets/icons/spring.svg");
   background-position: 80% 30%,left 80%;
   background-repeat: no-repeat;
}
.feedbackFormDiv{
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
    padding: 24px;
    min-width: 350px;
    max-width: fit-content;
    width: 100%;
}
.feedbackHeading{
    color:  #141719;
    font-family:"Inter",sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 127.273% */
    margin-bottom: 8px !important;
}
.feedbackSubHeading{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.imporvedFields{
    border-radius: 20px;
    background: #E9F6FF;
    padding: 8px 16px;
    color: #1A365D;
    text-align: center;
    font-family:  "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    cursor: pointer;
}
.feedbackSubmit {
    font-family:  "Inter",sans-serif;
    line-height: 20px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 6px 12px !important;
    height: 40px !important;
    background: #4299E1 !important;
    color:#FFFFFF !important;
    margin-top: 24px;
  }