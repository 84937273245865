.weekHeading{
    color: #5A646B;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: 0.1px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
}

.sessionDateNo{
    color:  #141719;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-right: 8px;
    margin-bottom: 18px;
}

.availableSlotText{
    color: #141719;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    text-align: left;
    margin-bottom: 8px !important;
}
.slotsDiv{
    border-radius: 10px;
    border: 1px solid  #A6B8C4;
    color: #141719;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    padding: 6px 12px;
    cursor: pointer;
}
.slotsDivMain{
    padding-bottom: 16px;
    border-bottom: 1px solid #D8E5EE;
}

.slotsInfoText{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-left: 8px;
}
.selectedSlotText{
    color:  #141719;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: 0.1px;
    margin-top: 8px;
    text-align: left;
}
.slotInfoDiv{
    border-radius: 12px;
    background:  #F3F8FB;
    align-items:flex-start;
    padding: 16px;
}
.confirmSlotText{
    color:  #141719;
    text-align: center;
    font-family: 'Inter',sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: 0.1px;
    text-align: center;
    padding: 8px;
}
.confirmSlotTimer{
    color:  #410002;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    border-radius: 0px 4px 4px 0px;
    background:  #FFEBF5;
    padding: 8px;   
   text-align: center;
}
.timerBlock{
   
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid  #A6B8C4;
}
.slotText{
    color: #1A365D;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-left: 8px;
}
.transactionSummaryText{
    color:  #141719;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 16px;
}
.summaryBox{
    border-radius: 4px;
    border: 1px solid  #A6B8C4;
    /* display: flex; */
    padding: 16px;
    /* align-items: center;
    justify-content: space-between; */
    
}
.summaryBoxLeftText{
    overflow: hidden;
    color:  #141719;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.summaryBoxCount{
    color:  #024742;
font-family: 'Inter',sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
border-radius: 20px;
border: 1px solid #3EB274;
padding: 2px 12px;
}

@media screen and (max-width:768px) {
    .slotsDiv{
        font-size: 12px;
        line-height: 18px; 
        padding: 6px 12px;
        
    }
   
}
@media screen and (max-width:767px) {
   
    .confirmSlotTimer{
        
        padding: 18px;   
       
    }
}
@media screen and (max-width:300px) {
    .slotsDiv{
        font-size: 12px;
        line-height: 18px; 
        padding: 6px ;
        
    }
    .confirmSlotText{
        font-size: 12px;
        line-height: 16px;
    }
    .confirmSlotTimer{
        font-size: 12px;
        line-height: 16px;
        padding: 14px 5px;
        align-items: center;
    }
}