.exerciseMainDiv {
    padding: 30px 72px 115px 72px;
}

.exerciseBackText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #4299E1;
    display: flex;
    margin-bottom: 5px;
    /* cursor: pointer; */
}

.exerciseNo {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #141719;
    margin: 13px 50px 8px 0;
    width: 180px;
}

.exerciseTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #141719;
    margin-top: 20px;
}
.exerciseSubTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #141719;
    margin-top: 20px;
    width: 200px;
}
.exerciseDescp {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5A646B;
    margin-top: 8px;
    margin-bottom: 25px;
}

.exerciseSubGoalIntro {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #5A646B;
    margin-top: 8px;
    /* margin-bottom: 48px; */
}

.exerciseGrid {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
}

.resourceBtn {
    font-family: 'Inter', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize;
    color: #141719 !important;
    border: 1px solid #4299E1;
    border-radius: 10px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px 20px;
    background: #FFFFFF !important;
    width: 184px;
}

.nextBtn {
    font-family: 'Inter', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize;
    color: #FFFFFF !important;
    background: #4299E1 !important;
    border-radius: 10px !important;
    width: 184px;

}

.exerciseBtnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
}
.mainDivBorders{
    border-radius: 10px;
    border: 1px solid  #A6B8C4;
    padding: 15px;
}
@media screen and (max-width:767px) {
    .exerciseMainDiv {
        padding: 24px 16px;
    }

    .exerciseBtnDiv {
        margin-top: 100px;
        margin-bottom: 100px;
        box-shadow: 0px -1px 4px rgba(66, 153, 225, 0.16);
        bottom: 0px;
        padding: 16px 16px 24px 16px;
        width: 100%;
    }

    .nextBtn {
        width: 100%;
    }

    .resourceBtn {
        width: 100%;
    }
}