.parentProfileMainBodyContainer {
    border: 1px solid #d8e5ee !important;
    border-radius: 16px 16px 0 0 !important;
    padding: 16px !important;
    margin-top: 24px !important;
  }
  .parentProfileMainBodyContainer2 {
    border: 1px solid #d8e5ee !important;
    border-radius: 0 0 16px 16px !important;
    padding: 16px !important;
    
  }
  .parentProfileAvatar {
    height: 48px !important;
    width: 48px !important;
  }
  .profileChildName{
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    color: #121314;
    text-transform: capitalize;
  }
  .profileAgeGender{
    color:  #5A646B;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    text-transform: capitalize;
  }
  .profileDivPadd{
    padding: 16px 72px;
  }
  .myProfileIndexFirstBoxButtonFlexParent {
    /* margin:-35px 0 20px 110px; */
    gap: 16px !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .profileLayoutBanner {
    background-image: url(../../../assets/images/layoutBg.svg);
    min-height: 128px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .uploadBtn{
    color:  #141719 !important;
    font-family: "Inter",sans-serif; 
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important; /* 142.857% */
    text-transform: capitalize;
    padding: 6px 12px;
    border-radius: 10px !important;
    border: 1px solid #A6B8C4;
    background:  #FFF !important;
    display: flex !important;
    justify-content:center !important;
    gap: 8px;
  }
  .childDocGrid{
    padding-right: 40px;
    /* width:240px !important; */
  }
  .childDocDiv{
    border-radius: 8px;
    border: 1px solid  #D8E5EE;
    background:  #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    width: 240px;
  }
  .childDocTitleDiv{ 
    display: -webkit-box;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* white-space: nowrap; */
    color: #141719;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
   max-height: 75px;
   max-width: 200px;
  }
  .uploadFileBtn{
    border-radius: 4px !important;
    border: 1px dashed  #63B3ED;
    background:  #E9F6FF !important;
    width: 100%;
    padding: 12px;
    color:  #1A365D !important;
    font-family: "Inter",sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important; /* 142.857% */
  }
  .fileNames{
    color:  #5A646B;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .singleFileDotFlex {
    gap: 2.75px !important;
    position: absolute !important;
    bottom: 0px !important;
    right:55px !important;
    height: 24px !important;
    cursor: pointer !important;
    transform: translate(-5px, -20px);
    width: 24px !important;
  }
  
  .singleFileDotFlex:hover {
    background: #e9f6ff !important;
  }
  .react-multiple-carousel__arrow{
    background:#3EB274 !important;
  }

  input::-webkit-date-and-time-value {
    text-align: left !important;
  }

  @media screen and (min-width:1024px) {
  .react-multi-carousel-list{
    max-width:calc(100vh + 230px) !important;
  }
  /* .react-multi-carousel-track
  {
    width:100vh !important;
  } */
}
  @media screen and (max-width:768px) {
    .myProfileIndexAvatar{
        transform: none !important;
        height: 48px !important;
        width: 48px !important;
      }
      .profileDivPadd{
        padding: 16px;
      }
  }
  @media screen and (max-width:767px) {
    .profileLayoutBanner{
        display: none !important;
      }
  }
  