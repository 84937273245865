.navItems {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3d464d !important;
  padding: 8px 16px !important;
}

.layoutBanner {
  background-image: url(../../assets/images/layoutBg.svg);
  min-height: 128px;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.username {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #141719;
  padding: 2px;
  margin-right: 10px;
}

.parentUsernameDiv {
  border: 1px solid #a6b8c4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.expertUsernameDiv {
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0px;
}

@media screen and (max-width:1100px) {
 
  .navItems {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px !important;
  }
}
@media screen and (max-width: 768px) {
  .parentUsernameDiv {
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .expertUsernameDiv {
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .username {
    display: none;
  }

  .hideMob {
    display: none !important;
  }

}
@media screen and (max-width:767px) {
  .layoutBanner{
      display: none !important;
    }
    .navItems {
     
      padding: 8px 12px !important;
    }
}
@media screen and (max-width:300px) {
 
    .navItems {
      
      padding: 5px !important;
    }
}
